import React, {useEffect} from "react";
import SisterCompanies from "../components/sister-companies";

export default function WhoWeAre() {

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const offset = 80; // Offset value in pixels
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;
        window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
      }
    }
  }, []);

  return (
      <div>
        <div
            className="flex flex-col lg:flex-row lg:items-center order-2 lg:order-1 flex-wrap gap-4 2xl:gap-16 bg-white"
            id="our-story"
        >
          <div className="order-2 lg:order-1 md:pl-16 ml-6 mr-6 leading-relaxed">
            <h1 className="text-[40px] font-din mb-3">Our Story</h1>
            <p className="max-w-lg mb-3 text-base font-light font-ibm">
              Joseph Benjamin Lawton began his printing journey in the late
              1800s at age 10. Years later after being shot for printing the truth
              in his Louisiana newspaper, he decided on a new venture in
              Orlando - pineapple farming. The severe freeze of 1899 forced J.B.
              Lawton to return to what he knew—printing. Lawton Press was
              formed in 1900, and our family&apos;s legacy began.
            </p><br/>
            <p className="max-w-lg text-base font-light font-ibm mb-2">
              The Lawton family tradition continued, with each son learning
              printing from a young age, expanding the business with each
              generation. J.C. Lawton took over until 1948, followed by J.B.
              Lawton III, and then Ces Lawton in 1981. In 2002, the 5th
              generation, Kimberly Lawton Koon, and husband, Ty Koon, took
              the helm driving growth with advanced technology and expanded equipment.
            </p>
          </div>
          <div className="order-1 lg:order-2 flex-1">
            <img src="./hero.png" alt="" className="h-[510px] object-cover w-full"/>
          </div>
        </div>
        <div className="bg-[#F2CB04] py-12">
          <div className="container justify-center flex flex-wrap items-center gap-5">
            <img src="./kimberly.jpg" alt="" className="w-[320px] h-[370px] object-cover"/>
            <img src="./tyler.jpg" alt="" className="w-[320px] h-[370px] object-cover"/>
            <div className="max-w-sm font-ibm ml-7">
              <p>
                Kimberly and Ty saw early on the exciting future in digital printing,
                automation, and diversification. With large investments in equipment,
                people, and processes, the company has grown to serve clients
                nationwide providing the highest quality customized printing. The
                development of our subsidiary companies and online platforms has
                only strengthened the success and efficiencies.
                <br/>
                <br/>
                Today, in our downtown Orlando facility, Lawton Connect remains the
                nation's longest consecutively family-owned printing company. Our
                commitment to excellence and client-trust spans five generations.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container flex items-center justify-center gap-12 py-20 flex-wrap">
            <img src="./new_women.png" alt="" className=""/>
            <p className="font-ibm font-light max-w-xs">
              Lawton Connect is a certified woman-owned business through the Women&apos;s Business
              Enterprise National Council (WBENC) and a Women Owned Small Business (WOSB) by the U.S.
              Small Business Administration.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center gap-16 pb-16 px-4 bg-black" id="our-commitment">
          <div className="hidden md:block">
            <img src="./main.png" alt="" className="w-[215px]"/>
          </div>
          <div>
            <h2 className="text-[40px] mb-5 mt-4 font-din text-white">Our Commitment</h2>
            <p className="max-w-lg font-ibm leading-snug text-white">
              While cultural norms and business models evolve and change, there&apos;s one thing
              we have learned throughout our 125+ year history which remains constant:
              Trust is hard to earn and easily broken. Across the nation and throughout our
              Central Florida home, it is trust that connects our team to your team, and it is
              our mission to protect it.
              <br/>
              <br/>
              Trust begins with learning about your objectives, audience, budget, and timeline,
              and then customizing an approach that solves your unique communication
              challenges. Our ongoing investments in equipment and technology provide
              flexibility for projects of virtually any quantity and any budget. We connect our
              experience, technology, team, and immeasurable resources to make the
              impossible a reality.
            </p>
          </div>
        </div>
        <div className="bg-[#1F66C3] py-8" id="core-values">
          <div className="max-w-6xl mx-auto text-white px-4">
            <h2 className="font-bold text-[40px] tracking-wide text-center mb-6 font-din">
              Our Core Values
            </h2>
            <div className="grid md:grid-cols-2 gap-y-6 gap-x-16 font-ibm">
              <div className="flex flex-col space-y-6">
                <div>
                  <h3 className="font-bold leading-none pb-1">PARTNERSHIP</h3>
                  <p className="font-d-din-normal font-light">
                    Fostering trusting relationships with customers, suppliers, our community, and
                    each other to grow the greater good
                  </p>
                </div>
                <div>
                  <h3 className="font-bold leading-none pb-1">COLLABORATION</h3>
                  <p className="">
                    Integrating our client&apos;s goals to create customized communications that make
                    dynamic and lasting impressions
                  </p>
                </div>
                <div>
                  <h3 className="font-bold leading-none pb-1">RESOURCEFULNESS</h3>
                  <p className="">
                    Finding innovative and creative ways to meet customer requirements and
                    expectations
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-6">
                <div>
                  <h3 className="font-bold leading-none pb-1">ACCOUNTABILITY</h3>
                  <p className="">
                    Influencing others in positive and proactive ways by taking responsibility for our
                    behaviors and actions
                  </p>
                </div>
                <div>
                  <h3 className="font-bold leading-none pb-1">EFFICIENCY</h3>
                  <p className="">
                    Finding ways to fully leverage our resources and partnerships to create the most
                    cost efficient solutions possible
                  </p>
                </div>
                <div>
                  <h3 className="font-bold leading-none pb-1">COMMITMENT</h3>
                  <p className="">
                    Pursuing excellence in behaviors, actions, knowledge, and delivery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SisterCompanies/>
      </div>
  );
}
